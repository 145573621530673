import React, { useEffect, useState } from 'react';
import { cannonStyled, useCannonStyletron } from '../../theme';
import CloseIcon from '../../assets/close.svg';
import { TWButtonDefault } from '../../theme';
import { CannonSafeThemeT } from '../../theme/CannonSafesTheme';
import { useTableOrMobile } from '../../hooks';

interface IProductManualsProps {
  isMobile?: boolean;
  $color?: string | undefined;
  thanksText?: string;
  $linkColor?: string;
  $theme?: CannonSafeThemeT;
}

interface IYellowWarning {
  thanksText: string;
  closeYellowWarning: (value: boolean) => void;
}

const YellowBar = cannonStyled('div', (props: IProductManualsProps) => ({
  width: '100%',
  backgroundColor: props.$theme?.cannonColors.primaryBoneClear,
  padding: '10px 0',
  margin: '0',
}));

const CloseDiv = cannonStyled('div', {
  marginRight: '20px',
  cursor: 'pointer',
});

const MaxWidthContainer = cannonStyled('div', {
  maxWidth: '1280px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '0 auto',
  padding: 0,
});

const RegisterLink = cannonStyled(
  'a',
  ({ $linkColor }: { $linkColor: string | undefined }) => ({
    textDecoration: 'underline',
    fontWeight: 700,
    color: $linkColor,
  })
);

const TextContainer = cannonStyled('div', {
  paddingLeft: '15px',
});

const ThanksContainer = (props: IProductManualsProps) => {
  return (
    <TextContainer>
      <TWButtonDefault
        color={props.$theme?.cannonColors.primaryBlueDark}
        weight={400}
        align={'left'}
        $style={{ fontSize: props.isMobile ? '14px' : '16px' }}
      >
        {props.thanksText}
      </TWButtonDefault>
    </TextContainer>
  );
};

const RegisterContainer = (props: IProductManualsProps) => {
  return (
    <TextContainer>
      <TWButtonDefault
        color={props.$theme?.cannonColors.primaryBlueDark}
        weight={400}
        align={'left'}
        $style={{ fontSize: props.isMobile ? '14px' : '16px' }}
      >
        Haven't you registered your safe? We highly recommend you going through
        this one-minute process. &nbsp;
        <RegisterLink
          href='/register-your-safe'
          $linkColor={props.$linkColor}
        >
          Register your safe
        </RegisterLink>
      </TWButtonDefault>
    </TextContainer>
  );
};

const YellowWarning = (props: IYellowWarning) => {
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const [showElement, setShowElement] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 10000);
  }, []);

  return (
    <>
      {showElement && (
        <>
          <YellowBar $color={theme.cannonColors.primaryBone}>
            <MaxWidthContainer>
              {props.thanksText ? (
                <ThanksContainer isMobile={isMobile} thanksText={props.thanksText}/>
              ) : (
                <RegisterContainer
                  isMobile={isMobile}
                  $linkColor={theme.cannonColors.primaryBlue}
                />
              )}
              <CloseDiv onClick={() => props.closeYellowWarning(true)}>
                <CloseIcon style={{stroke: theme.cannonColors.primaryBlueDark}} />
              </CloseDiv>
            </MaxWidthContainer>
          </YellowBar>{' '}
        </>
      )}
    </>
  );
};

export default YellowWarning;
