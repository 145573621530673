import React, { useEffect, useMemo, useState } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import UserManualSafes from '../components/sections/UserManualSafes';
import { ResponsiveContainer } from '../atoms/Container';
import { Button, CannonBreadcrumbs } from '../atoms';
import { Filter } from '../components/sections/UserManualsFilters';
import { useCoreAPIURL, useTableOrMobile } from '../hooks';
import { cannonStyled, NSH3, TWH1, useCannonStyletron } from '../theme';
import { ICannonSafe } from '.';
import { ThemeProvider } from 'baseui';
import YellowWarning from '../atoms/YellowWarning';
import analyticsCustomEvent from '../atoms/js/analyticsCustomEvent';
import RegisterSafeTabs, {
  IFullInformation,
} from '../components/sections/RegisterSafeFormBySteps/RegisterSafeTabs';
import { IAboutYourSafe } from '../components/sections/RegisterSafeFormBySteps/AboutYourSafe';
import ErrorForm from '../atoms/Cards/ErrorForm';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { IFileImage } from '../@types';
import UserManualLocks from '../components/sections/UserManualLocks';

export interface IData {
  allStrapiLockTypes: AllStrapiLockTypes;
  strapiUserManualsByLockType: StrapiUserManualsByLockType;
}

export interface AllStrapiLockTypes {
  nodes: INode[];
}

export interface INode {
  lockType: ILockType[];
}

export interface ILockType {
  displayImage: IFileImage;
  hierarchy: string;
  description: string;
  displayName: string;
  manual: IFileImage;
  slug: string;
}
export interface StrapiUserManualsByLockType {
  title: string;
  subtitle: string;
}

// GRAPHQL QUERY
const query = graphql`
  query {
    allStrapiLockTypes {
      nodes {
        lockType {
          displayImage {
            url
          }
          hierarchy
          slug
          description
          displayName
          manual {
            url
          }
        }
      }
    }
    strapiUserManualsByLockType {
      title
      subtitle
    }
  }
`;

// STYLED COMPONENTS

const BreadCrumbsContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '16px' : '10px',
    width: '100%',
  })
);

const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '50px 0px 0px 0px' : '50px 0px 0px 0px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

// COMPONENT
const UserManuals: React.FC<PageProps> = (props: PageProps) => {
  // HOOKS
  const data = useStaticQuery<IData>(query);
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();

  // STATE

  // HANDLERS & FUNCTIONS

  // EFFECTS & CALLBACKS

  // RENDER
  const coreAPI_URL = useCoreAPIURL();

  const [showSuccess, setShowSuccess] = useState(false);
  const [flag, setFlag] = useState(true);
  const [showFetchError, setShowFetchError] = useState(false);
  const [thanksText, setThanksText] = useState('');
  const [showElement, setShowElement] = useState(true);
  const [historyUrl, setHistoryUrl] = useState('');
  const [showRegistration, setShowRegistration] = useState(false);

  const { title = '', subtitle = '' } = data.strapiUserManualsByLockType;
  const { nodes = [] as INode[] } = data.allStrapiLockTypes;
  const { lockType = [] as ILockType[] } = nodes[0];

  useEffect(() => {
    setTimeout(function () {
      setShowElement(false);
    }, 13000);
  }, []);

  async function sendInfo(value: any) {
    const response = await fetch(coreAPI_URL + '/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: value }),
    })
      .then((r) => r.json())
      .then((r) => {
        return r;
      })
      .catch((e) => setShowFetchError(true));
  }

  async function handleSubmitClicked(value: any) {
    const { about = {} as IAboutYourSafe } = value;
    const { proofOfPurchase = {} as File, serialNumber } = about;
    const myRenamedFile = new File(
      [proofOfPurchase],
      new Date().getTime().toString() +
        '-' +
        serialNumber.toString() +
        '-upload-register-safe-file' +
        '.' +
        proofOfPurchase.name.split('.')[
          proofOfPurchase.name.split('.').length - 1
        ]
    );
    const formData = new FormData();
    formData.append('file', myRenamedFile);
    const response = await fetch(coreAPI_URL + '/upload', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        // Accept: 'multipart/form-data',
      },
      body: formData,
    })
      .then((r) => r.json())
      .then((r) => {
        const { data = {} as { url: string }, message } = r;
        if (message === 'File saved.') {
          const { url = '' } = data;
          value.about.proofOfPurchase = url;
          sendInfo(value);
        } else {
          setShowFetchError(true);
        }
        return r;
      })
      .catch((e) => setShowFetchError(true));
  }

  let comesFromQR: boolean = false;
  let alreadyRegister: boolean = false;

  useEffect(() => {
    if (typeof window === 'undefined' || !window.document) {
      return;
    } else {
      setHistoryUrl(document.referrer);
      alreadyRegister = localStorage.getItem('alreadyRegister') === 'true';
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const utm_source = urlParams.get('source');
      comesFromQR = utm_source === 'QR Code on Product' ? true : false;
    }
  }, []);

  useEffect(() => {
    setShowRegistration(comesFromQR && isMobile && !alreadyRegister && flag);
  }, [flag]);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <div id='main'>
        {showFetchError && <ErrorForm />}
        {showRegistration ? (
          <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
            <ResponsiveContainer>
              <RegisterSafeTabs
                RegisterCompleted={function (value: IFullInformation): void {
                  scrollTo('#main');
                  setShowSuccess(true);
                  setFlag(false);
                  setThanksText('Thank you for registering your safe!');
                  handleSubmitClicked(value);
                  analyticsCustomEvent('register_safe');
                  localStorage.setItem('alreadyRegister', 'true');
                }}
              />
            </ResponsiveContainer>
          </MainWrapper>
        ) : (
          <>
            <YellowWarning
              thanksText={thanksText}
              closeYellowWarning={(dataInfo: boolean) => {
                setShowElement(false);
              }}
            />
            <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
              <BreadCrumbsContainer isMobile={isMobile}>
                <CannonBreadcrumbs
                  pages={[
                    { title: 'Home', href: '/' },
                    { title: 'Support', href: '' },
                    { title: 'Manuals', href: `/user-manuals` },
                  ]}
                />
              </BreadCrumbsContainer>
              <ResponsiveContainer>
                <UserManualLocks
                  locks={lockType}
                  title={title}
                  subtitle={subtitle}
                />
              </ResponsiveContainer>
            </MainWrapper>
          </>
        )}
      </div>
    </LayoutProvider>
  );
};

export default UserManuals;
